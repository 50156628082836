/* labels */
.app-feature-animation-labels .swiper-slide-thumb-active div {
  @apply !text-black transition-colors duration-200 ease-in-out after:absolute after:-bottom-1 after:left-0 after:h-1 after:w-0 after:bg-black after:transition-all after:duration-200 hover:after:w-0 desktop:py-1 desktop:hover:after:w-full;
}

/* medias */
.app-feature-animation-medias .swiper-wrapper {
  @apply !max-h-full !max-w-full;
}

/* pagination */
.medias-pagination .swiper-pagination-bullet {
  @apply !m-0 h-3 w-3 bg-[#99E4D7] opacity-40 transition-all duration-300;
}

.medias-pagination .swiper-pagination-bullet-active {
  @apply w-9 rounded-e-lg rounded-s-lg bg-teal opacity-100;
}

/* cta */
.app-feature-animation-cta button {
  @apply mx-auto h-auto min-h-fit w-10/12 bg-navy px-4 py-3 text-base !text-white hover:bg-teal desktop:w-full;
}
