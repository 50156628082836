.be-ix-link-block .be-related-link-container .be-label {
  margin: 0;
  color: white;
  line-height: 1em;
  font-variant-numeric: lining-nums;
  font-weight: 600;
}

.be-ix-link-block .be-related-link-container .be-list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.be-ix-link-block .be-related-link-container .be-list .be-list-item {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 6px;
}

.be-ix-link-block .be-related-link-container .be-list .be-list-item .be-related-link {
  font-weight: 600;
  color: #fff;
  font-size: .9em;
}

.be-ix-link-block .be-related-link-container .be-list .be-list-item .be-related-link:hover {
  text-decoration: underline;
  color: #3BBFAD;
}

.be-ix-link-block .be-related-link-container .be-list .be-list-item:last-child {
  margin-right: 0;
}

@media (max-width: 1023px) {
  .be-ix-link-block .be-related-link-container .be-label {
    width: 100%;
    margin-bottom: 10px;
  }

  .be-ix-link-block .be-related-link-container .be-list {
    display: block;
    width: 100%;
  }

  .be-ix-link-block .be-related-link-container .be-list .be-list-item {
    display: block;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .be-ix-link-block .be-related-link-container {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }

  .be-ix-link-block .be-related-link-container .be-label {
    display: inline-block;
    margin-right: 20px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
