@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Hairline.otf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-HairlineItalic.otf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Thin.otf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-ThinItalic.otf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-LightItalic.otf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Screen.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-ScreenItalic.otf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Regular.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-RegularItalic.otf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Greenlight Repro Display';
    src: url('./GreenlightRepro-RegularDisplay.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Medium.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-MediumItalic.otf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-BoldItalic.otf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Black.otf');
    font-weight: 800;
    font-display: auto;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-BlackItalic.otf');
    font-weight: 800;
    font-style: italic;
}


@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-Ultra.otf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Greenlight Repro';
    src: url('./GreenlightRepro-UltraItalic.otf');
    font-weight: 900;
    font-style: italic;
}