@import '@greenlightme/tailwind-config/css/button.css';
@import '@greenlightme/tailwind-config/css/typography.css';
@import "tailwindcss/components";
@import "./components.css";
@tailwind base;
@tailwind utilities;

.column {
    @apply box-border flex shrink-0 grow-0 ;
}

.block-container {
    @apply w-full flex justify-center scroll-mt-16 box-border; 
}

.desktop-block-container {
    @apply w-full hidden desktop:flex justify-center scroll-mt-16 box-border; 
}

.hero-container {
    @apply w-full justify-center scroll-mt-16 box-border;
}

.block-container > * {
    @apply max-w-screen-max;
  }  

.layout {
    @apply flex flex-wrap flex-1 justify-center items-center box-border w-full px-5 tablet:px-10 desktop:px-14 desktop:py-10;
}

.box-column {
    @apply shrink-0 grow-0 box-border;
}

.heading-2 {
    @apply font-glReproDisplay tablet:text-heading2 leading-[115%] text-heading2-mobile;
}

.heading-3 {
    @apply font-normal text-xl tablet:text-2xl;
}

.heading-display {
    @apply desktop:text-[4rem] desktop:leading-[115%] text-heading2 leading-[46px]
}

.text-display-3 {
    @apply font-glRepro text-[28px] desktop:text-[48px] font-normal leading-[36px] desktop:leading-[56px] tracking-normal
}

.body-medium {
    @apply text-xl font-normal leading-8;
}

.body-small {
    @apply text-base font-normal;
}

.body-extra-small {
    @apply text-xs leading-5 desktop:text-sm;
}

.primary-button {
    @apply grow desktop:grow-0 shrink basis-0 h-10 px-4 font-medium py-2 rounded-lg justify-center items-center gap-2.5 flex;
}

.body-link {
    @apply text-primary text-center text-lg underline decoration-solid relative;
}

h1, h2, h3 {
    @apply font-glReproDisplay font-normal;
}

p {
    @apply font-light;
}

.styled-unordered-list { 
    @apply p-0 mb-6; 
} 

.styled-unordered-list > li { 
    /* Use your green checkmark URL below */
    background: url('../images/green-checkmark-circle.svg') no-repeat; 
    background-size: 24px 24px; 
    @apply pl-8 mb-4 list-none; 
} 

.styled-unordered-list > li > p { 
    @apply leading-6; 
} 

.styled-unordered-list > li > ul:first-of-type { 
    @apply pl-8; 
} 

.styled-unordered-list > li > ul:first-of-type > li { 
    @apply bg-none list-disc pl-0 mb-2; 
}

@keyframes fade-scale-in {
  0% {
    transform: scale(1.4);
    opacity: 0;
  }

  90% {
    transform: scale(.98);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  90% {
    opacity: 1;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-scale-in {
  animation-name: fade-scale-in;
  animation-duration: .5s;
}

.animate-fade-slide-in-up {
  animation-name: fade-slide-in-up;
  animation-duration: .5s;
}

.styled-feature-description-list > li { 
  background: url('../images/checkmark-bullets.svg') no-repeat; 
  background-size: 16px 16px;
  background-position: left 0px top 4px;
  @apply pl-6 mb-4 list-none flex items-center;
  font-size: 16px;
}
