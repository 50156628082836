.plans-table-body > tr {
  @apply border-t-0;
}

.plans-table-body > tr td {
  @apply border-b border-b-[#dbdbdb];
}

.plans-table-body > tr:last-child {
  @apply border-none;
}

.plans-table-container {
  @apply ml-5 !justify-normal overflow-x-scroll rounded-l-4xl !px-0 !pt-[72px] 
    tablet:mx-10 tablet:!justify-center tablet:overflow-auto tablet:rounded-4xl tablet:!pt-10 
    desktop:mx-15 desktop:flex-col desktop:items-center desktop:gap-2 desktop:rounded-3xl desktop:!px-4 desktop:!py-8;
}

.mobile-core-nudge {
  @apply absolute right-4 top-7 flex align-middle !font-medium;
}

@media (min-width: 425px) {
  .mobile-core-nudge {
    @apply hidden;
  }
}

.sticky-left-column::after {
  content: '';
  position: absolute;
  left: 16px;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  z-index: -1;
}

.sticky-left-column {
  @apply !border-b-0 tablet:!border-b tablet:border-b-[#dbdbdb];
}
