@media (min-width: 1024px) {
#popup-iframe-64ac647710e15271b66648ed{
    display: inline-block;
 }
}

#chatbase-bubble-button {
  bottom: 75px !important;
}

#chatbase-message-bubbles {
  bottom: 155px !important;
}

@media (min-width: 641px) {
  #chatbase-bubble-window {
    bottom: 155px !important;
    max-height: 669px !important; /* A fixed height for larger screens */
  }
}
