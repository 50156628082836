:root {
  --swiper-pagination-progressbar-size: 8px;
  --swiper-theme-color: #80d6b0;
  --swiper-pagination-color: #80d6b0;
}

.carousel-bullet-container {
  @apply relative mr-2 mt-4 flex h-11 flex-1 cursor-pointer flex-col items-center justify-center py-[10px] desktop:mt-0 desktop:flex-none;
}

.carousel-bullet {
  @apply relative inline-block h-1 w-full rounded-[2px] bg-[rgba(255,255,255,0.3)] shadow-inner desktop:w-[72px];
}

.custom-bullet-inner {
  @apply absolute left-0 top-0 inline-block h-full w-0 bg-white;
}

.carousel-pagination {
  @apply !left-0 !top-0 flex h-fit pl-4 pr-4 lg:absolute desktop:!left-[inherit] desktop:!top-[inherit] desktop:pb-14 desktop:pl-20 desktop:pr-0 desktop:!text-left;
}
